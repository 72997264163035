<template>
  <div>
    <vs-button
      @click="viewList"
      type="border"
      size="small"
      icon-pack="feather"
      icon="icon-list"
    ></vs-button>
    <vs-popup :active.sync="popupActive" class="log-popup">
      <div class="bg-white rounded-lg shadow-lg max-w-lg w-full flex flex-col log-popup-body">
        <div class="p-6 log-header">
          <div class="flex justify-between items-center">
            <h2 class="text-xl font-semibold text-gray-900">{{ voucherCode }}</h2>
          </div>
          <p class="text-gray-600 mt-2">Activity log for the voucher code.</p>
        </div>
        <div class="p-6 space-y-4 flex-grow overflow-auto">
          <div v-if="loading">
              <p class="text-gray-600">Loading logs...</p>
          </div>
          <div v-else>
            <div v-if="voucherLogs.length > 0" class="flex flex-col gap-4">
              <div
                v-for="log in voucherLogs" 
                :key="log.id"
                class="border border-gray-200 rounded-lg flex justify-between items-center"
              >
                <vs-card class="log-item">
                  <div class="text-base">
                    <p class="text-gray-800 flex min-w-0 pr-3 font-semibold">
                      {{ log.userId ? log.userId.clinicName : "-" }}
                    </p>
                    <p class="text-gray-800 flex flex-col sm:flex-row sm:gap-2">
                      <span class="flex-shrink-0 font-medium">
                        {{ formatDate(log.createdAt) }}
                      </span>
                      <span class="text-gray-600 font-extrabold hidden sm:block">
                        •
                      </span>
                      <span class="text-gray-600 client-name">
                        {{ log.orderId.shippingDetails.fullName }}
                      </span>
                    </p>
                  </div>
                  <vs-button 
                    class="px-4 py-2 font-semibold hidden sm:block"
                    type="border"
                    @click="viewOrderDetail(log.orderId._id)"
                  >
                    View Order
                  </vs-button>
                  <vs-button
                    @click="viewOrderDetail(log.orderId._id)"
                    class="sm:hidden"
                    type="flat"
                    size="large"
                    icon-pack="feather"
                    icon="icon-chevron-right"
                  ></vs-button>
                </vs-card>
              </div>
            </div>
            <div v-else>
              <p class="text-gray-600">No logs available for this voucher.</p>
            </div>
          </div>
        </div>
        <div class="p-6 log-footer text-center">
          <vs-button
            @click="popupActive=false"
            color="primary"
            class="w-full px-4 py-2 max-w-sm"
          >
            Close
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapActions } from 'vuex/dist/vuex.common.js';

  export default {
    name: "VoucherActivityLog",
    props: ["voucherId", "voucherCode"],
    data() {
      return {
        popupActive: false,
        userName: "[User’s First + Last name]",
        voucherLogs: [],
        loading: false,
      };
    },
    methods: {
      ...mapActions("voucher", [
        "getVoucherHistory"
      ]),
      async getList(voucherId) {
        this.loading = true;

        const res = await this.getVoucherHistory(voucherId)
        this.voucherLogs = res.data.data;

        this.loading = false;
      },
      viewList() {
        this.getList(this.voucherId);
        this.popupActive = true;
      },
      viewOrderDetail(orderId) {
        // this.$router.push({
        //   name: "AdminStoreViewOrder",
        //   params: { orderId: orderId }
        // });

        const url = `/super-admin/store/orders/view/${orderId}`;
        window.open(url, "_blank");
      },
      formatDate(date) {
        return moment(date).format('MMM DD, hh:mm a');
      },
    },
  };
</script>

<style scoped>
  .log-popup .log-popup-body {
    max-height: 90vh;
  }

  .log-header {
    border-bottom: 1px solid #E5E7EB;
  }

  .log-footer {
    border-top: 1px solid #E5E7EB;
  }
</style>

<style>
  .log-popup .vs-popup--content {
    overflow: unset !important;
    width: 100%;
  }

  .log-item {
    margin-bottom: 0;
  }

  .log-item strong {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .log-item .client-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
  }

  .log-item button {
    border-color: #E5E7EB;
    flex-shrink: 0;
  }

  .log-item button i {
    font-size: 2rem !important;
  }

  .log-item .vs-card--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    padding: 10px 20px;
  }

  @media (min-width: 576px) {
    .log-item .vs-card--content {
      align-items: stretch;
    }
  }

  .log-item .vs-card--content > div {
    min-width: 0;
  }
</style>