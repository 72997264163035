<template>
  <vx-card>
    <div class="w-full justify-end  flex mb-2">
        <CreateVoucherPopUp @fetchList="getList" :editData="editData" :dataTableParams="dataTableParams" @close="closePopup"/>
    </div>
    <VoucherList
      :data="data"
      :totalDocs="totalDocs"
      :page="page"
      :popUpDeleteParent="popUpDelete"
      :noDataText="noDataText"
      :dataTableParams="dataTableParams"
      @fetchList="getList"
      @editDetail="editDetail"
      @deleteItem = "deleteItem"
    />
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import VoucherList from "../../components/voucher/VoucherList.vue";
import CreateVoucherPopUp from "../../components/voucher/CreateVoucherPopUp.vue";

export default {
  name:"ListDiscount",
  components: {
    VoucherList,
    CreateVoucherPopUp
  },
  data() {
    return {
      editData: null,
      totalDocs: 0,
      page: 1,
      popUpDelete:false,
      data: [],
      noDataText: 'Loading...',
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 10
      },
    };
  },
  methods: {
    ...mapActions("voucher", [
      "getVoucherList",
      "deleteVoucherById"
    ]),
    ...mapActions("discount", [
      "getDiscountList",
      "deleteDiscountById"
    ]),
    async getList(dataTableParams) {
      const res = await this.getVoucherList(this.dataTableParams)
        this.data = res.data.data.docs;
        this.totalDocs = res.data.data.pagination.total || 0;
        this.page = res.data.data.pagination.page;
        this.currentPage = res.data.data.pagination.page;
        this.noDataText = 'No Discount Available'
    },
    closePopup(){
      this.editData = null;
    },
    editDetail(id) {
      this.editData = this.data.find(item => item._id === id)
    },
    async deleteItem(data){
      try {
        await this.deleteVoucherById(data.id)
        this.popUpDelete = false;
        this.getList(data.dataTableParams);
        this.$vs.notify({
          title: "Success",
          text: "Discount Deleted successfully.",
          color: "success"
        });
      } catch (error) {
        this.popUpDelete = false;
        this.$vs.notify({
          title: err.data.title,
          text: err.data.message,
          color: "danger"
        });

      }
    }
  },
};
</script>
