<template>
  <div>
    <vs-button
      @click="popupActive=true"
      color="primary"
      icon-pack="feather"
      icon="icon-edit"
      >
      Add Voucher
    </vs-button>
    <vs-popup class="popUpCustomCreate"  :active.sync="popupActive">
      <vs-card>
        <form autocomplete="off">
          <div class="p-3 flex-col space-y-2" >
            <div class="group-card space-y-2">
              <div >
                <vs-input
                  :danger="errors.first('discount_title') ? true : false"
                  :danger-text="errors.first('discount_title')"
                  :success="
                    !errors.first('discount_title') && voucherData.discount_title != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  v-validate="'required'"
                  data-vv-as="discount_title"
                  name="discount_title"
                  label="Discount Name"
                  placeholder="Discount Name"
                  v-model="voucherData.discount_title"
                  class="w-full"
                />
              </div>
              <div >
                <vs-input
                  :danger="errors.first('voucher_code') ? true : false"
                  :danger-text="errors.first('voucher_code')"
                  :success="
                    !errors.first('voucher_code') && voucherData.voucher_code != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  v-validate="'required'"
                  data-vv-as="voucher_code"
                  name="voucher_code"
                  label="Voucher Code"
                  placeholder="Voucher Code"
                  v-model="voucherData.voucher_code"
                  class="w-full"
                />
              </div>
            </div>
            <div class="group-card space-y-2">
              <label for="discount_type" class="label">Discount value</label>
              <div class=" block sm:flex sm:space-x-1">
                <div class="flex-grow">
                  <div class="relative">
                    <div class="discountTypeContainer flex items-center justify-between" :class="{
                        'input-validate-danger':
                        errors.first('discount_type') ,
                        'input-icon-validate-danger':
                        errors.first('discount_type')
                    }">
                        <div class="flex space-x-5 p-2">
                          <vs-radio v-model="voucherData.discount_type" vs-name="discount_type" vs-value="percentage">Percentage</vs-radio>
                          <vs-radio v-model="voucherData.discount_type" vs-name="discount_type" vs-value="fix">Fix</vs-radio>
                        </div>
                        <span class="icon-container input-icon-validate vs-input--icon-validate">
                          <i
                            v-if="
                              errors.first('discount_type')"
                            valiconpack="material-icons"
                            class="vs-icon notranslate icon-scale material-icons null "
                            >error</i
                          >
                        </span>
                    </div>
                  </div>
                </div>
                <div class="sm:w-1/2">
                  <vs-input
                    type="number"
                    :danger="errors.first('discount_value') ? true : false"
                    :danger-text="errors.first('discount_value')"
                    :success="
                      !errors.first('discount_value')
                      && voucherData.discount_value
                      && voucherData.discount_value != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    v-validate="'required|min_value:1'"
                    :icon="voucherData.discount_type === 'percentage' ? '%' : null"
                    data-vv-as="discount_value"
                    name="discount_value"
                    placeholder="Voucher Value"
                    v-model="voucherData.discount_value"
                    class="w-full"
                  />
                </div>
              </div>
            </div>
            <div class="group-card space-y-2">
              <label for="minRequirement" class="label">Minimum Purchase requirement</label>
              <div class="relative">
                <div class="discountTypeContainer flex items-center justify-between" :class="{
                    'input-validate-danger':
                    errors.first('minRequirement') ,
                    'input-icon-validate-danger':
                    errors.first('minRequirement')
                }">
                    <div class="flex sm:space-x-5 p-2 flex-wrap sm:flex-no-wrap">
                      <vs-radio v-model="minRequirement" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="minRequirement" vs-value="no-requirement">No Requirement</vs-radio>
                      <vs-radio v-model="minRequirement" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="minRequirement" vs-value="amount">Minimum purchase amount ($)</vs-radio>
                      <vs-radio v-model="minRequirement" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="minRequirement" vs-value="quantity">Minimum quantity of items</vs-radio>
                    </div>
                    <span class="icon-container input-icon-validate vs-input--icon-validate">
                      <i
                        v-if="
                          errors.first('minRequirement')"
                        valiconpack="material-icons"
                        class="vs-icon notranslate icon-scale material-icons null "
                        >error</i
                      >
                    </span>
                </div>
              </div>
              <div v-if="minRequirement === 'amount'">
                <div class="mb-2">
                  <vs-input
                    type="number"
                    :danger="errors.first('min_amount') ? true : false"
                    :danger-text="errors.first('min_amount')"
                    :success="
                      !errors.first('min_amount')
                      && voucherData.min_amount
                      && voucherData.min_amount != ''
                    "
                    v-validate="'min_value:0'"
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="min_amount"
                    name="min_amount"
                    :min="0"
                    label="Minimum amount"
                    v-model="voucherData.min_amount"
                    class="w-full"
                  />
                </div>
                <div>
                  <vs-textarea
                    :danger="errors.first('display_message_min_amount') ? true : false"
                    :danger-text="errors.first('display_message_min_amount')"
                    :success="
                      !errors.first('voucher_code') && voucherData.display_message_min_amount != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="display_message_min_amount"
                    name="display_message_min_amount"
                    label="Minimum Amount Message"
                    v-model="voucherData.display_message_min_amount"
                    class="w-full"
                  />
                </div>
              </div>
              <div v-if="minRequirement === 'quantity'">
                <div class="mb-2">
                  <vs-input
                    type="number"
                    :danger="errors.first('min_quantity') ? true : false"
                    :danger-text="errors.first('min_quantity')"
                    :success="
                      !errors.first('min_quantity')
                      && voucherData.min_quantity
                      && voucherData.min_quantity != ''
                    "
                    v-validate="'min_value:0'"
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="min_quantity"
                    name="min_quantity"
                    label="Minimum Quantity"
                    v-model="voucherData.min_quantity"
                    class="w-full"
                  />
                </div>
                <div >
                  <vs-textarea
                  :danger="errors.first('display_message_min_qty') ? true : false"
                  :danger-text="errors.first('display_message_min_qty')"
                  :success="
                      !errors.first('display_message_min_qty') && voucherData.display_message_min_qty != ''
                    "
                    val-icon-success="done"
                    val-icon-danger="error"
                    data-vv-as="display_message_min_qty"
                    name="display_message_min_qty"
                    label="Minimum Quantity Message"
                    v-model="voucherData.display_message_min_qty"
                    class="w-full"
                    />
                  </div>
              </div>

            </div>
            <div class="group-card space-y-2">
              <label for="customerEligibility" class="label">Customer eligibility</label>
              <!-- Organizations -->
              <div class="">
                <label for="selectedOrganization"  class="label">Organization</label>
                <div class="relative" :class="{
                      'input-validate-danger':
                      errors.first('selectedOrganization') && voucherData.selectedOrganization != '',
                      'input-icon-validate-danger':
                      errors.first('selectedOrganization') && voucherData.selectedOrganization != ''
                    }">
                    <multiselect
                    id="selectedOrganizations"
                    v-model="selectedOrganization"
                    class="voucher-multiselect"
                    :options="organizationsList"
                    label="name"
                    :loading="!!organizationsList.length===0"
                    placeholder="Select Organization"
                    :clear-on-select="false"
                    track-by="id"
                    :max-height="200"
                    multiple
                    />
                  <vs-icon
                    v-if="selectedOrganization.length > 0"
                    icon="close"
                    color="red"
                    bg="white"
                    class="removeSelectedFilters"
                    @click="selectedOrganization = []">
                  </vs-icon>
                  <span class="icon-container input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        errors.first('selectedOrganization') && voucherData.selectedOrganization != ''"
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null "
                      >error</i
                    >
                  </span>
                </div>
              </div>
              <!-- clinics -->
              <div class="">
                <label for="selectedClinic" class="label">Clinics</label>
                <div class="relative" :class="{
                  'input-validate-danger':
                  errors.first('selectedClinic') && voucherData.selectedClinic != '',
                  'input-icon-validate-danger':
                  errors.first('selectedClinic') && voucherData.selectedClinic != ''
                }">
                  <multiselect
                    id="selectedClinic"
                    class="voucher-multiselect"
                    v-model="selectedClinic"
                    :options="clinicsList"
                    multiple
                    label="clinicName"
                    :loading="!!clinicsList.length===0"
                    placeholder="Select Clinic"
                    :clear-on-select="false"
                    track-by="_id"
                    :max-height="200"
                    />
                  <vs-icon
                    v-if="selectedClinic.length > 0"
                    icon="close"
                    color="red"
                    bg="white"
                    class="removeSelectedFilters"
                    @click="selectedClinic = []">
                  </vs-icon>
                  <span class="icon-container input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        errors.first('selectedClinic') && voucherData.selectedClinic != ''"
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null "
                      >error</i
                    >
                  </span>
                </div>
              </div>
            </div>
            <div class="group-card space-y-2">
              <label for="customerEligibility" class="label">Product eligibility</label>
              <!-- products -->
              <div >
                <label for="selectedProducs">Products</label>
                <div class="relative" :class="{
                  'input-validate-danger':
                  errors.first('selectedProducs') && voucherData.selectedProducs != '',
                  'input-icon-validate-danger':
                  errors.first('selectedProducs') && voucherData.selectedProducs != ''
                }">
                  <multiselect
                    id="selectedProducs"
                    class="voucher-multiselect"
                    v-model="selectedProducts"
                    :options="productsList"
                    multiple
                    label="name"
                    :loading="!!productsList.length===0"
                    placeholder="Select Product"
                    :clear-on-select="false"
                    track-by="_id"
                    :max-height="200"
                    />
                  <vs-icon
                    v-if="selectedProducts.length > 0"
                    icon="close"
                    color="red"
                    bg="white"
                    class="removeSelectedFilters"
                    @click="selectedProducts = []">
                  </vs-icon>
                  <span class="icon-container input-icon-validate vs-input--icon-validate">
                    <i
                      v-if="
                        errors.first('selectedProducs') && voucherData.selectedProducs != []"
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null "
                      >error</i
                    >
                  </span>
                </div>
              </div>
            </div>

            <div class="group-card space-y-2">
              <label for="discount_limit" class="label">Maximum Discount uses</label>
              <div class="relative">
                <div class="discountTypeContainer flex items-center justify-between" :class="{
                    'input-validate-danger':
                    errors.first('discount_limit') ,
                    'input-icon-validate-danger':
                    errors.first('discount_limit')
                }">
                    <div class="flex sm:space-x-5 p-2 flex-wrap sm:flex-no-wrap">
                      <vs-radio v-model="voucherData.discount_limit" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="discount_limit" vs-value="total-limit">Limit number of times discount can be used in total</vs-radio>
                      <vs-radio v-model="voucherData.discount_limit" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="discount_limit" vs-value="per-client">Limit to one use per clinic</vs-radio>
                    </div>
                    <span class="icon-container input-icon-validate vs-input--icon-validate">
                      <i
                        v-if="
                          errors.first('discount_limit')"
                        valiconpack="material-icons"
                        class="vs-icon notranslate icon-scale material-icons null "
                        >error</i
                      >
                    </span>
                </div>
              </div>
              <div class="mb-2" v-if="voucherData.discount_limit === 'total-limit'">
                <vs-input
                  type="number"
                  :danger="errors.first('max_use') ? true : false"
                  :danger-text="errors.first('max_use')"
                  :success="
                    !errors.first('max_use')
                    && voucherData.max_use
                    && voucherData.max_use != ''
                  "
                  v-validate="'min_value:0'"
                  :min="0"
                  val-icon-success="done"
                  val-icon-danger="error"
                  data-vv-as="max_use"
                  name="max_use"
                  label="Maximum Use"
                  v-model="voucherData.max_use"
                  class="w-full"
                />
              </div>
            </div>

            <div class="group-card space-y-2">
              <label for="discount_type" class="label">Combination</label>
              <div class="relative">
                <div class="discountTypeContainer flex items-center justify-between" :class="{
                    'input-validate-danger':
                    errors.first('combination') ,
                    'input-icon-validate-danger':
                    errors.first('combination')
                }">
                    <div class="flex sm:space-x-5 p-2 flex-wrap sm:flex-no-wrap">
                      <!-- <vs-checkbox v-model="voucherData.combination" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="combination" vs-value="product-discount">Product Discount</vs-checkbox> -->
                      <vs-checkbox v-model="voucherData.combination" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="combination" vs-value="shipping-discount">Shipping Discount</vs-checkbox>
                      <vs-checkbox v-model="voucherData.combination" class="w-full sm:w-auto mb-2 sm:mb-0" vs-name="combination" vs-value="other-discount">Other Discount</vs-checkbox>
                    </div>
                    <span class="icon-container input-icon-validate vs-input--icon-validate">
                      <i
                        v-if="
                          errors.first('combination')"
                        valiconpack="material-icons"
                        class="vs-icon notranslate icon-scale material-icons null "
                        >error</i
                      >
                    </span>
                </div>
              </div>
            </div>

            <div class="group-card space-y-2">
              <label for="applyAfterGST" class="label">Voucher Discount</label>
              <div class="relative">
                <div class="discountTypeContainer flex items-center justify-between">
                    <div class="flex items-center justify-left gap-3 w-full">
                      <vs-checkbox v-model="voucherData.applyAfterGST" />
                      <span>
                        <div class="text-sm">[&check;] Apply discount after GST and Card Fees <strong><i>(default)</i></strong></div>
                        <div class="text-sm">[ &nbsp; ] Apply discount before GST and Card Fees</div>
                      </span>
                    </div>
                </div>
              </div>
            </div>


            <div class="group-card space-y-2">
              <label class="label" >Voucher Validity</label>
              <div class="block sm:flex sm:space-x-5 mt-2">
                <!-- start date -->
                <div class="flex-grow">
                  <Datepicker
                    format="dd MMMM yyyy"
                    v-model="voucherData.rule_valid_from"
                    :typeable="true"
                    placeholder="Valid from"
                  />
                </div>
                <!-- end date -->
                <div class="sm:w-1/2">
                  <Datepicker
                    format="dd MMMM yyyy"
                    v-model="voucherData.rule_valid_to"
                    :typeable="true"
                    placeholder="Valid to"
                  />
                </div>
              </div>
            </div>
            <div class="flex flex-wrap md:flex-no-wrap items-center justify-between space-y-2 md:space-y-0 md:space-x-4 mt-5">
              <div class="w-full md:flex-1 downloadPdf">
                <vs-button type="border" class="w-full" @click="popupActive=false">
                  Cancel
                </vs-button>
              </div>
              <div class="w-full md:flex-1">
                <vs-button class="w-full" @click="voucherCreateSubmit">Submit</vs-button>
              </div>
            </div>
          </div>
        </form>
      </vs-card>
    </vs-popup>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    discount_title: {
      required: "Discount name is required"
    },
    voucher_code: {
      required: "Voucher code is required"
    },
    discount_value: {
      required: "Voucher value is required",
      min_value: "Voucher value must be greater than 0"
    },
    min_amount: {
      min_value: "Minimum amount must be 0 or more"
    },
    min_quantity: {
      min_value: "Minimum quantity must be 0 or more"
    },
    max_use: {
      min_value: "Maximum use value must be greater than or equal to 0"
    }
  }
};
Validator.localize("en", dict);

export default{
  name: "CreateVoucherPopUp",
  components:{
    Multiselect,
    Datepicker
  },
  props:["editData", "dataTableParams"],
  data(){
    return{
      popupActive: false,
      organizationsList: [],
      selectedOrganization:[],
      clinicsList:[],
      selectedClinic:[],
      productsList:[],
      selectedProducts:[],
      minRequirement: 'no-requirement',
      voucherData:{
        voucher_code: "",
        discount_type: "",
        discount_value: null,
        discount_title: "",
        min_amount: 0,
        min_quantity: 0,
        display_message_min_amount: "",
        display_message_min_qty: "",
        // is_org_selected: false,
        discount_limit: null,
        max_use: 0,
        combination:[],
        // use: 10,
        rule_valid_from:"",
        rule_valid_to: "",
        applyAfterGST: true,
      }
    }
  },
  methods:{
    ...mapActions("organization", ["fetchOrganizationAssignList", "fetchOrganizationDetails"]),
    ...mapActions("clinic", ["fetchAllClinic"]),
    ...mapActions("product", ["fetchAllProductslist"]),
    ...mapActions("voucher", ["createNewVoucher", "updateVoucher"]),
    async getOrganizationsList(id){
      const res = await this.fetchOrganizationAssignList({id});
      if(res.data.data.length){
        this.organizationsList = res.data.data;
      }
    },
    async getAllClinic() {
      const res = await this.fetchAllClinic()
      this.clinicsList = await res.data.data;
    },
    async getAllProducts() {
      const res = await this.fetchAllProductslist()
      this.productsList = await res.data.data;
    },
    async sendToServer(){
      const isValid = await this.$validator.validateAll();
      if (isValid) {
        try {
          this.$vs.loading();
          const data = {
            ...this.voucherData,
            is_org_selected: this.selectedOrganization.length > 0,
            orgId: this.selectedOrganization.map(org => org.id),
            clinicId: this.selectedClinic.map(clinic => clinic._id),
            productId: this.selectedProducts.map(product => product._id)
          }
          if(this.editData){
            const updateRes = await this.updateVoucher({data, id: this.editData._id});
            if (updateRes.status === 200) {
              this.$vs.notify({
                title: "Success",
                text: "Voucher updated successfully.",
                color: "success",
              });
            }
          }
          else{
            const createRes = await this.createNewVoucher(data);
            if (createRes.status === 200) {
              this.$vs.notify({
                title: "Success",
                text: "Voucher created successfully.",
                color: "success",
              });
            }
          }
          this.$vs.loading.close();
          await this.$emit("fetchList", this.dataTableParams);
          this.resetFields()
        } catch (error) {
          let errMessage = error.data.message ? error.data.message.toLowerCase().split(/\s+/) : [];
          let formatErrorMessage = error.data.message;
          if (errMessage.length && errMessage.includes("duplicate")){
            formatErrorMessage = "Voucher code exists. Please enter a new one."
          }
          this.$vs.notify({
            title: "Failed",
            text: formatErrorMessage,
            color: "danger",
          });
          this.$vs.loading.close();
          console.log(error);
        }
      }
    },
    resetFields(){
        this.voucherData.voucher_code = "";
        this.voucherData.discount_type = "";
        this.voucherData.discount_value = null;
        this.voucherData.discount_title = "";
        this.voucherData.min_amount = 0;
        this.voucherData.min_quantity = 0;
        this.voucherData.display_message_min_amount = "";
        this.voucherData.display_message_min_qty = "";
        this.voucherData.discount_limit = null;
        this.voucherData.max_use = 0;
        this.voucherData.combination = [];
        this.voucherData.rule_valid_from = "";
        this.voucherData.rule_valid_to = "";
        this.voucherData.applyAfterGST = true;
        this.selectedOrganization = [];
        this.minRequirement = 'no-requirement';
        this.selectedClinic = [];
        this.selectedProducts = [];
        this.$emit("close")
        this.popupActive = false;
    },
    setValues(data){
      this.voucherData.voucher_code = data.voucher_code;
      this.voucherData.discount_type = data.discount_type;
      this.voucherData.discount_value = data.discount_value;
      this.voucherData.discount_title = data.discount_title;
      this.voucherData.min_amount = data.min_amount;
      this.voucherData.min_quantity = data.min_quantity;
      this.voucherData.discount_limit = data.discount_limit;
      this.voucherData.display_message_min_amount = data.display_message_min_amount;
      this.voucherData.display_message_min_qty = data.display_message_min_qty;
      this.voucherData.combination = data.combination || [];
      this.voucherData.max_use = data.max_use;
      this.voucherData.applyAfterGST = data.applyAfterGST;
      this.voucherData.rule_valid_from = data.rule_valid_from ? new Date(data.rule_valid_from) : null;
      this.voucherData.rule_valid_to = data.rule_valid_to ? new Date(data.rule_valid_to) : null;
      this.selectedOrganization = this.organizationsList.filter(item => data.orgId.includes(item.id));
      this.minRequirement = data.min_amount > 0 ?"amount": data.min_quantity > 0 ? "quantity" : "no-requirement";
      const clinicids = data.clinics.map(product => product.clinicId);
      this.selectedClinic = this.clinicsList.filter(item => clinicids.includes(item._id))
      const productids = data.products.map(product => product.productId);
      this.selectedProducts = this.productsList.filter(item => productids.includes(item._id))
    },
    voucherCreateSubmit(){
      let isValid = true;
      if (
        !this.voucherData.discount_type ||
        !['fix',"percentage"].includes(this.voucherData.discount_type)
      ) {
        isValid = false;
        this.errors.add({
          field: "discount_type",
          msg: "Select a valid discount type.",
        });
      } else {
        this.errors.remove("discount_type");
      }
      if (
        !this.voucherData.discount_limit ||
        !['total-limit','per-client'].includes(this.voucherData.discount_limit)
      ) {
        isValid = false;
        this.errors.add({
          field: "discount_limit",
          msg: "Select a valid discount limit.",
        });
      } else {
        this.errors.remove("discount_limit");
      }
      // if(!this.selectedOrganization.length && !this.selectedClinic.length){
      //   isValid = false;
      //   this.errors.add({
      //     field: "selectedOrganization",
      //     msg: "Select a valid selectedOrganization.",
      //   });
      //   this.errors.add({
      //     field: "selectedClinic",
      //     msg: "Select a valid selectedClinic.",
      //   });
      // }
      // else{
      //   this.errors.remove("selectedOrganization");
      //   this.errors.remove("selectedClinic");
      // }
      if(isValid){
        this.sendToServer();
      }
    },
    removeDuplicateObjects(array, key) {
      const seen = new Set();
      return array.filter(item => {
          const value = item[key];
          if (!seen.has(value)) {
              seen.add(value);
              return true;
          }
          return false;
      });
    },
    async prepopulateSelectedClinics(orgIds) {
      try {
        const populatedClinics = [];
        const formattedClinics = [];
        await Promise.all(
          orgIds.map(async(id) => {
            const res = await this.fetchOrganizationDetails(id);
            if (res.status === 200 && res.data.data.clinicorg.length) {
              res.data.data.clinicorg.map(({clinicId}) => {
                populatedClinics.push(clinicId)
              });
            }
          })
        )
        if (populatedClinics.length) {
          populatedClinics.map((id) => {
            formattedClinics.push(this.clinicsList.filter(({_id}) => _id.toString() === id.toString())[0])
          })
        }
        if (formattedClinics.length) {
          this.selectedClinic = this.removeDuplicateObjects([...this.selectedClinic, ...formattedClinics], "_id");
        }
      } catch (err) {
        console.error(err)
      }
    }
  },
  async created(){
    await this.getOrganizationsList(this.$store.state.AppActiveUser._id);
    this.getAllClinic();
    this.getAllProducts();
  },
  watch:{
    editData: function (newVal, oldVal) {
      if (!oldVal && newVal){
        this.setValues(newVal)
        this.popupActive = true
      }
    },
    popupActive: function (newVal) {
      if(!newVal){
        this.resetFields()
      }
    },
    minRequirement: function (newVal) {
      if(newVal === "no-requirement"){
        this.voucherData.min_amount = 0;
        this.voucherData.min_quantity = 0;
      }
      else if(newVal === "quantity"){
        this.voucherData.min_amount = 0;
      }
      else if(newVal === "amount"){
        this.voucherData.min_quantity = 0;
      }
    },
    "voucherData.discount_limit": function (newVal) {
      if(newVal !== 'total-limit'){
        this.voucherData.max_use = 0;
      }
    },
    selectedOrganization: {
      handler(val) {
        if (val.length) {
          const orgIds = val.map(({_id}) => _id);
          this.prepopulateSelectedClinics(orgIds);
        }
      }
    }
  }
}
</script>

<style>
  .popUpCustomCreate .vs-card--content {
    max-height: 75vh;
    overflow: auto;
    margin-bottom: 0 !important;
    padding: 0 !important;
  }
  .popUpCustomCreate .con-vs-card{
    border: none !important;
    margin-bottom:  0 !important;
  }
  .popUpCustomCreate  {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
  }
  .popUpCustomCreate .multiselect__tags .multiselect__single {
    font-size: 14px !important;
  }
  .popUpCustomCreate .multiselect__input  {
    font-size: 14px !important;
  }
  .popUpCustomCreate .multiselect__content  {
    font-size: 14px !important;
  }
  .popUpCustomCreate .vdp-datepicker ::placeholder{
    color: #adadad !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    opacity: 50;
  }
  .popUpCustomCreate .vdp-datepicker {
    font-size: 14px !important;
  }
  .popUpCustomCreate .vs-input input::placeholder{
    color: #adadad !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    opacity: 50;
  }
  .popUpCustomCreate .vs-input .vs-input--label {
    font-size: 14px !important;
  }
  .popUpCustomCreate .vs-input {
    font-size: 14px !important;
  }
  .popUpCustomCreate .vs-input input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .popUpCustomCreate .vs-textarea-primary {
    color: #626262;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
  }
  .popUpCustomCreate .vs-textarea-primary h4 {
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .popUpCustomCreate .vs-textarea-primary ::placeholder{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
    color: #adadad !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    opacity: 50;
  }
  .popUpCustomCreate .vs-textarea-primary textarea{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
  }
  .popUpCustomCreate .discountTypeContainer{
    border-radius: 5px;
    height: 37.59px !important;
  }
  .popUpCustomCreate .input-validate-danger{
    border-radius: 6px;
    border: 1px solid #e27b81;
  }
  .popUpCustomCreate .input-validate-danger .icon-container{
    background-color: #e27b81e3;
  }
  .popUpCustomCreate .label{
    padding-left: 5px !important;
    font-size: 14px !important;
  }
  .popUpCustomCreate .multiselect__tags{
    border: 1px solid #0003 !important
  }
  .downloadPdf .vs-button{
    padding: 8px 12px 8px 12px !important;
  }
  .vs-button-primary{
    border: 1px solid rgba(var(--vs-primary), 1);
  }
  .voucher-multiselect .multiselect__tag, .multiselect__option--highlight {
    background-color: #3CC9B2;
    color: white;
  }
  .group-card{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .5rem/* 8px */;
    padding: 0.75rem/* 12px */;
    flex-direction: column;
  }
</style>
